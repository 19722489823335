import React from 'react';
import { Stack, Skeleton } from '@mui/material';
//import theme
import { useTheme } from '@mui/material/styles'; 

const DynamicSkeletonStack = ({ count }) => {
  const skeletonCount = Math.floor(count / 8);
  const theme = useTheme();

  return (
    <Stack spacing={2} direction="column" width="100%">
      {[...Array(skeletonCount)].map((_, index) => (
        <Skeleton
          key={index}
          height="30px"
          width="100%"
          animation="wave"
          sx={{
            mb: 2,
            px: 2,
            py: 2,
            bgColor: theme.palette.text.primary,
          }}
        />
      ))}
    </Stack>
  );
};

export default DynamicSkeletonStack;
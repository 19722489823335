import React from 'react';
import { Box, Typography, List, ListItemText } from '@mui/material';
// import { privacyPolicy, termsOfService } from '../utils/privacyPolicyAndTermsOfServiceText.js';

const PrivacyPolicy = () => {
  return (
    <>
      <Typography variant="h5" paragraph>
        We collect minimum data about you and your usage of the app to provide you with the app services.
        Data we collect:
      </Typography>
      <List>
        <ListItemText primary="Your Email Address" />
        <ListItemText primary="Your Typing Speed in random tests" />
        <ListItemText primary="Your custom profiles" />
        <ListItemText primary="Your preference of theme, font and avatar" />
      </List>
      <Typography variant="h5" paragraph>
        Data we save on your browser locally, and don't send to our servers:
      </Typography>
      <List>
        <ListItemText primary="Your advanced settings" />
        <ListItemText primary="Your selected language" />
        <ListItemText primary="Your selected difficulty" />
        <ListItemText primary="Your selected test length" />
      </List>
      <Typography variant="h5" paragraph>
        Data we share with third parties:
      </Typography>
      <List>
        <ListItemText primary="We use Google Firebase to authenticate you" />
      </List>
    </>
    
  );
}

const TermsOfService = () => {
  return (

    <>
      <Typography variant="body1" paragraph>
        By using Typecelerate, you agree to use the app responsibly and at your own risk. We are not liable for any issues arising from the use of our service. You may not use our app for illegal activities. We reserve the right to modify these terms at any time.
      </Typography>
    </>
  );

}


  function PrivacyPolicyTermsOfServicePage() {
    return (
      <Box
        sx={{ width: "50%", m: "1em" }}>
        <Typography variant="h4">Privacy Policy</Typography>
        <PrivacyPolicy />
        <Typography variant="h4" style={{ marginTop: '1rem' }}>Terms of Service</Typography>
        <TermsOfService />
      </Box>
    );
  }

  export { PrivacyPolicyTermsOfServicePage };